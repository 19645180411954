import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PastIncidentsSection.css";
import { format } from "date-fns";

export const API_BASE_URL =
  process.env.REACT_APP_DEBUG === "true"
    ? process.env.REACT_APP_API_BASE_URL_DEBUG
    : process.env.REACT_APP_API_BASE_URL_PROD;

function PastIncidentsSection() {
  const [incidents, setIncidents] = useState([]);

  useEffect(() => {
    axios
      .get(API_BASE_URL + "/incidents")
      .then((response) => {
        console.log("Fetched incidents:", response.data);
        setIncidents(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the incidents!", error);
      });
  }, []);

  const formatDateRange = (startDate, endDate) => {
    const start = format(new Date(startDate), "MMM d, yyyy HH:mm");
    if (!endDate) {
      return `Since: ${start} UTC - (Ongoing)`;
    }
    const end = format(new Date(endDate), "MMM d, yyyy HH:mm");
    return `${start} UTC to ${end} UTC`;
  };

  return (
    <div className="past-incidents-section">
      <h2>Past Incidents</h2>
      <div className="incidents-list">
        {incidents.length > 0 ? (
          incidents.map((incident) => (
            <div key={incident.id} className="incident-item">
              <div className="incident-date">
                {formatDateRange(incident.start_date, incident.end_date)}
              </div>
              <div className="incident-title">{incident.title}</div>
              <div className="incident-details">{incident.details}</div>
            </div>
          ))
        ) : (
          <p>No incidents to display.</p>
        )}
      </div>
    </div>
  );
}

export default PastIncidentsSection;
