import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import { Tooltip } from "react-tooltip";
import { ChevronDown } from "lucide-react";
import "./StatusSection.css";
import { parseISO, differenceInDays, subDays, format } from "date-fns";
import { toDate } from "date-fns-tz";

export const API_BASE_URL =
  process.env.REACT_APP_DEBUG === "true"
    ? process.env.REACT_APP_API_BASE_URL_DEBUG
    : process.env.REACT_APP_API_BASE_URL_PROD;

const statusMessages = {
  "All Systems Operational": {
    color: "rgb(14, 149, 111)",
    message: "All Systems Operational",
  },
  "Potential Minor Service Disruption": {
    color: "rgba(227, 212, 113, 0.881)",
    message: "Potential Minor Service Disruption",
  },
  "Performance Issues Detected": {
    color: "rgba(227, 212, 113, 0.881)",
    message: "Performance Issues Detected",
  },
  "Partial System Outage": {
    color: "rgb(238, 103, 103)",
    message: "Partial System Outage",
  },
  "All Systems Down": {
    color: "rgb(238, 103, 103)",
    message: "All Systems Down",
  },
};

const serviceUrls = {
  "Beacon AI": process.env.REACT_APP_BEACON_REDIRECTION_URL,
  "gPod-AI Podcast": process.env.REACT_APP_GPOD_REDIRECTION_URL,
  "Moodle LMS": process.env.REACT_APP_MOODLE_LMS_URL,
  WPEngine: process.env.REACT_APP_WPENGINE_URL,
  "Main Website": process.env.REACT_APP_MAIN_WEBSITE_URL,
  "OpenAI Service": process.env.REACT_APP_OPENAI_SERVICE_URL,
  HubSpot: process.env.REACT_APP_HUBSPOT_URL,
  "Main Landing Page": process.env.REACT_APP_MAIN_LANDING_PAGE_URL,
  "Kentucky Landing Page": process.env.REACT_APP_KENTUCKY_LANDING_PAGE_URL,
  "Denmark Landing Page": process.env.REACT_APP_DENMARK_LANDING_PAGE_URL,
  "Denmark Vercel": process.env.REACT_APP_DENMARK_VERCEL_URL,
  "South Carolina Vercel": process.env.REACT_APP_SOUTH_CAROLINA_VERCEL_URL,
  "FIU Vercel": process.env.REACT_APP_FIU_VERCEL_URL,
};

const calculateOperationalPercentage = (daysStatus) => {
  const filteredStatus = daysStatus.filter(
    (status) =>
      status === "operational" || status === "degraded" || status === "down"
  );

  const totalDays = filteredStatus.length;
  const operationalDays = filteredStatus.filter(
    (status) => status === "operational"
  ).length;

  return totalDays > 0
    ? ((operationalDays / totalDays) * 100).toFixed(2)
    : "0.00";
};

const generateDaysStatus = (daysData) => {
  const statuses = new Array(60).fill("unknown");
  const todayUTC = toDate(new Date(), { timeZone: "UTC" });

  daysData.forEach((day) => {
    const dateUTC = toDate(parseISO(day.date), { timeZone: "UTC" });
    const diffDays = differenceInDays(todayUTC, dateUTC);

    if (diffDays >= 0 && diffDays < 60) {
      statuses[59 - diffDays] = day.status;
    }
  });

  return statuses;
};

const getTooltipMessageCurrent = (status) => {
  switch (status) {
    case "operational":
      return "All services currently operational";
    case "degraded":
      return "Some services experiencing issues";
    case "down":
      return "Services experiencing down-time";
    default:
      return "Status unknown";
  }
};

const getTooltipMessageHistory = (status, index) => {
  const todayUTC = toDate(new Date(), { timeZone: "UTC" });
  const date = subDays(todayUTC, 59 - index);
  const formattedDate = format(date, "MM/dd/yyyy");

  switch (status) {
    case "operational":
      return `${formattedDate} (UTC): Services did not experience any issues`;
    case "degraded":
      return `${formattedDate} (UTC): Some services experienced issues`;
    case "down":
      return `${formattedDate} (UTC): Services experienced down-time`;
    default:
      return `${formattedDate} (UTC): Status not tracked`;
  }
};

const serviceGroups = {
  "Landing Pages": [
    "Main Landing Page",
    "Kentucky Landing Page",
    "Denmark Landing Page",
  ],
  "Vercel Apps": ["Denmark Vercel", "South Carolina Vercel", "FIU Vercel"],
};

function StatusSection() {
  const [services, setServices] = useState([]);
  const [overallStatus, setOverallStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedGroups, setExpandedGroups] = useState({});

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(API_BASE_URL + "/status")
      .then((response) => {
        console.log("Fetched data:", response.data);
        setServices(response.data.services);
        setOverallStatus(response.data.overall_status);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setIsLoading(false);
      });
  }, []);

  const getGroupStatus = (groupServices) => {
    if (groupServices.every((service) => service.status === "operational")) {
      return "operational";
    } else if (groupServices.some((service) => service.status === "down")) {
      return "down";
    } else {
      return "degraded";
    }
  };

  const toggleGroupExpansion = (groupName) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  const renderServiceGroup = (groupName, groupServices) => {
    const groupStatus = getGroupStatus(groupServices);
    const isExpanded = expandedGroups[groupName];

    return (
      <div key={groupName} className="service-group">
        <div
          className={`service-status-item group-item ${
            isExpanded ? "expanded" : ""
          }`}
          onClick={() => toggleGroupExpansion(groupName)}
        >
          <div
            className={`status-circle ${groupStatus}`}
            data-tooltip-id={`current-status-${groupName}`}
          >
            <Tooltip
              id={`current-status-${groupName}`}
              place="top"
              effect="solid"
            >
              {getTooltipMessageCurrent(groupStatus)}
            </Tooltip>
          </div>
          <span>{groupName}</span>
          <ChevronDown
            className={`expand-icon ${isExpanded ? "expanded" : ""}`}
          />
        </div>
        {isExpanded && (
          <div className="group-services">
            {groupServices.map((service) => (
              <div key={service.name} className="service-status-item nested">
                <div
                  className={`status-circle ${service.status.toLowerCase()}`}
                  data-tooltip-id={`current-status-${service.name}`}
                >
                  <Tooltip
                    id={`current-status-${service.name}`}
                    place="top"
                    effect="solid"
                  >
                    {getTooltipMessageCurrent(service.status)}
                  </Tooltip>
                </div>
                <span>
                  <a
                    href={serviceUrls[service.name]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {service.name}
                  </a>
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="status-section">
      {isLoading ? (
        <div className="loader"></div>
      ) : (
        <>
          <div className="system-status">
            <span
              className={`status-label ${overallStatus.replace(/\s+/g, "-")}`}
            >
              {statusMessages[overallStatus]?.message || "Status Unknown"}
            </span>
          </div>
          <div className="service-status-list">
            {services
              .filter(
                (service) =>
                  !Object.values(serviceGroups).flat().includes(service.name)
              )
              .map((service) => (
                <div key={service.name} className="service-status-item">
                  <div
                    className={`status-circle ${service.status.toLowerCase()}`}
                    data-tooltip-id={`current-status-${service.name}`}
                  >
                    <Tooltip
                      id={`current-status-${service.name}`}
                      place="top"
                      effect="solid"
                    >
                      {getTooltipMessageCurrent(service.status)}
                    </Tooltip>
                  </div>
                  <span>
                    <a
                      href={serviceUrls[service.name]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {service.name}
                    </a>
                  </span>
                </div>
              ))}
            {Object.entries(serviceGroups).map(
              ([groupName, groupServiceNames]) => {
                const groupServices = services.filter((service) =>
                  groupServiceNames.includes(service.name)
                );
                return groupServices.length > 0
                  ? renderServiceGroup(groupName, groupServices)
                  : null;
              }
            )}
          </div>
          <p className="uptime-message">Uptime History (past 60 days):</p>
          <Accordion type="single" collapsible>
            {services.map((service) => {
              const daysStatus = generateDaysStatus(
                service.daily_statuses || []
              );
              const operationalPercentage =
                calculateOperationalPercentage(daysStatus);

              return (
                <AccordionItem key={service.name} value={service.name}>
                  <AccordionTrigger>
                    <div className="status-item">
                      <span>
                        <a
                          href={serviceUrls[service.name]}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {service.name}
                        </a>
                      </span>
                      <div className="status-days">
                        {daysStatus.map((status, index) => (
                          <div
                            key={index}
                            className={`status-day ${status}`}
                            data-tooltip-id={`status-day-${service.name}-${index}`}
                          >
                            <Tooltip
                              id={`status-day-${service.name}-${index}`}
                              place="top"
                              effect="solid"
                            >
                              {getTooltipMessageHistory(status, index)}
                            </Tooltip>
                          </div>
                        ))}
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div
                      className={`status-card ${service.status.toLowerCase()}`}
                    >
                      {operationalPercentage}% uptime
                    </div>
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
        </>
      )}
    </div>
  );
}

export default StatusSection;
